import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import Img from 'gatsby-image'

import recentBlogPostsSkipStyle from './recentBlogPostsSkipStyle.jsx'

import classNames from 'classnames'

import { graphql, Link, useStaticQuery } from 'gatsby'
import ReactRevealFadeIn from '../../components/ReactRevealFadeIn/ReactRevealFadeIn'
import Section from '../../components/Section/Section'
import SectionHeader from '../../components/SectionHeader/SectionHeader'


const RecentBlogPostsSkip = props => {

  const { classes,articles } = props

  return (
    <Section backgroundColor={'#fff'}>
      <SectionHeader header={'Blog'} subHeader={'The Guide'}/>

      <div className={classes.mainWrapper}>
        <div className={classes.firstWrapper}>
          {articles.slice(0, 1).map(article => {
            return (
              <ReactRevealFadeIn>


                <Link to={props.actLang === "de" ? 'de/guide/' + article.url :'guide/' + article.url} >


                  <Img
                    className={classNames(classes.bigImage, classes.imageDarkenOnHover)}
                    style={{ width: '100%', height: '100%' }}
                    fluid={article.featuredImage.fluid}
                    alt={article.featuredImage.alt}
                    backgroundColor={'#f2f2f2'}
                    objectFit="contain"
                    objectPosition="50% 50%"
                  />
                </Link>

                <Link to={props.actLang === "de" ? 'de/guide/' + article.url :'guide/' + article.url} >
                  <div className={classes.bigArticleContent}>
                    <h3 className={classes.H2}>
                      {article.title}
                    </h3>
                    <p className={classes.P}>
                      {article.summary ?
                        article.summary.length > 140 ? article.summary.slice(0, 140) + '...' : article.summary
                        : null}

                    </p>
                    <p className={classes.smallDate}> {article.meta.updatedAt}</p>
                  </div>
                </Link>

              </ReactRevealFadeIn>
            )
          })}


        </div>
        <div className={classes.secondWrapper}>
          {articles.slice(1, 5).map(article => {
            return (
              <ReactRevealFadeIn>

                <div className={classes.smallAricleWrapper}>
                  <Link className={classes.linkClass} to={props.actLang === "de" ? 'de/guide/' + article.url :'guide/' + article.url} >
                    <Img
                      className={classNames(classes.smallImage, classes.imageDarkenOnHover)}

                      fluid={article.featuredImage.fluid}
                      alt={article.featuredImage.alt}
                      backgroundColor={'#f2f2f2'}
                      objectFit="contain"
                      objectPosition="50% 50%"
                    />
                  </Link>

                  <div className={classes.smallArticleContent}>
                    <Link to={props.actLang === "de" ? 'de/guide/' + article.url :'guide/' + article.url} >
                      <h3 className={classes.H3}>
                        {article.title}
                      </h3>
                    </Link>
                    <Link to={props.actLang === "de" ? 'de/guide/' + article.url :'guide/' + article.url} >
                      <p className={classes.smallDate}>{article.meta.updatedAt}</p>
                    </Link>
                  </div>

                </div>


              </ReactRevealFadeIn>
            )
          })}


        </div>
      </div>


    </Section>
  )

}

export default withStyles(recentBlogPostsSkipStyle)(RecentBlogPostsSkip)
