import React from 'react'
import { graphql } from 'gatsby'

import Footer from 'components/Footer/Footer.jsx'

import withStyles from '@material-ui/core/styles/withStyles'

import postStyle from './postStyle'
import PageHeader from '../../components/PageHeader/PageHeader'
// import RecentBlogPosts from '../../elements/RecentBlogPosts/RecentBlogPosts'
import Content from '../../elements/Content/Content'
import Section from '../../components/Section/Section'
import RecentBlogPostsSkip from '../../elements/RecentBlogPostsSkip/RecentBlogPostsSkip'
import HeaderProvider from '../../components/Header/HeaderProvider'
import BannerWithImg from '../../elements/BannerWithImg/BannerWithImg'
import SEO from '../../components/SEO'

class PostTemplate extends React.Component {
  render() {
    const { classes, data } = this.props
    const { featuredImage, title, subtitle, url, meta } = data.datoCmsArticle


    const modified = []
    data.datoCmsArticle._allUrlLocales.map(item => {
      modified.push({ 'locale': item.locale, 'value': 'guide/' + item.value })
    })
    console.log('modified', modified)
    return (
      <div className={classes.backgroundColor}>
        <SEO
          seoMetaTags={data.datoCmsArticle.seoMetaTags.tags}
          article
          actLang={this.props.pageContext.locale}
          localURLS={modified}
        />
        <HeaderProvider
          localURLS={modified}
          actLang={this.props.pageContext.locale}
          SEOtitle={title}
          SEOdescription={subtitle}
          SEOimage={featuredImage.fluid.src}
          SEOpathname={url}
          SEOarticle

        />
        <PageHeader
          big
          image={featuredImage.fluid}
          title={title}
          subtitle={subtitle}
          date={meta.updatedAt}
        />
        <Section
          paddingTop={0}
          paddingBottom={60}
          innerStyles={{ maxWidth: 705 }}
        >
          <Content content={data.datoCmsArticle.content}/>
        </Section>
        <BannerWithImg
          title={data.datoCmsHome.bannerWithBackgroundImage[0].title}
          text={data.datoCmsHome.bannerWithBackgroundImage[0].text}
          image={data.datoCmsHome.bannerWithBackgroundImage[0].image}
          buttonTitle={data.datoCmsHome.bannerWithBackgroundImage[0].buttonTitle}
          link={data.datoCmsHome.bannerWithBackgroundImage[0].buttonLink.url}
          actLang={this.props.pageContext.locale}
        />
        <RecentBlogPostsSkip articles={data.allDatoCmsArticle.nodes} actLang={this.props.pageContext.locale}/>

        <Footer
          actLang={this.props.pageContext.locale}
        />
      </div>
    )
  }
}

export const query = graphql`
    query($slug: String! $locale: String!) {
        datoCmsSite(locale:{eq:$locale}){
            globalSeo{
                siteName
                titleSuffix
                twitterAccount
                facebookPageUrl
                fallbackSeo{
                    title
                    description
                    twitterCard
                    image{url}
                }
            }
        }
        datoCmsArticle(url: { eq: $slug } locale:{eq:$locale}) {
            seoMetaTags{
                tags
            }
            _allUrlLocales{locale value}
            featuredImage {
                alt
                fluid(maxWidth:1800) {
                    src
                    ...GatsbyDatoCmsFluid_noBase64
                }
            }
            title
            subtitle
            url
            meta {
                updatedAt(formatString: "MM-DD-YYYY")
            }
            content {
                __typename
                ... on DatoCmsYoutubeVideo {
                    link
                }
                ... on DatoCmsText {
                    text
                }
                ... on DatoCmsCtaWetsuitFinder {
                    locale
                }
                ... on DatoCmsBannerWithBgImage {
                    title
                    text
                    image{
                        alt
                        fluid(maxWidth:1800){
                            ...GatsbyDatoCmsFluid_noBase64
                        }
                    }
                    buttonTitle
                    buttonLink{
                        ...on DatoCmsSizeFinder{
                            url
                        }
                        ...on DatoCmsWetsuitFinder{
                            url
                        }

                    }

                }
                ... on DatoCmsImage {
                    image {
                        alt
                        fluid(maxWidth: 705) {
                            src
                            ...GatsbyDatoCmsFluid_noBase64
                        }
                    }
                }
                ... on DatoCmsProductChart {
                    locale
                    affiliateCountry
                    amount
                    humanType
                    priceRangeMax
                    priceRangeMin
                    season
                }
            }
        }
        allDatoCmsArticle(
            filter:{
                url:{ne:$slug}
                meta:{status:{eq:"published"}  }
                locale:{eq:$locale}
            }
        ){
            nodes{
                locale
                url
                title
                meta{
                    updatedAt(formatString: "MM-DD-YYYY")
                }
                summary
                featuredImage{
                    alt
                    fluid(maxWidth:705){
                        src
                        ...GatsbyDatoCmsFluid_noBase64
                    }
                }
            }
        }
        datoCmsHome(locale:{eq:$locale}) {
            bannerWithBackgroundImage{
                title
                text
                image{
                    alt
                    fluid(maxWidth:1800){
                        ...GatsbyDatoCmsFluid_noBase64
                    }
                }
                buttonTitle
                buttonLink{
                    ...on DatoCmsSizeFinder{
                        url
                    }
                    ...on DatoCmsWetsuitFinder{
                        url
                    }
                }
            }
            bannerBlank{
                title
                subtitle
                buttonLink
                buttonTitle
            }
        }
    }
`

export default withStyles(postStyle)(PostTemplate)
